const boutiques = {
    country: 'Россия',
    selected: true,
    location: {
        lat: 55.762480,
        long: 37.614473,
    },
    items: [
        {
            city: 'Москва',
            selected: 1,
            location: {
                lat: 55.762480,
                long: 37.614473,
            },
            stores: [
                {
                    location: {
                        lat: 55.754679,
                        long: 37.621661,
                    },
                    title: 'ГУМ',
                    address: 'Красная площадь, 3, ТЦ "ГУМ"',
                    phone: '8 (495) 995 72 45',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.760798,
                        long: 37.619773,
                    },
                    title: 'ЦУМ',
                    address: 'Улица Петровка, 2, ТЦ "ЦУМ"',
                    phone: '8 (495) 646 74 39',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 11:00 - 23:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.747485,
                        long: 37.581298,
                    },
                    title: 'Смоленский Пассаж',
                    address: 'Смоленская площадь, 3',
                    phone: '8 (495) 771 65 75',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.762328,
                        long: 37.614087,
                    },
                    title: 'Большая Дмитровка',
                    address: 'Большая Дмитровка, 18с2',
                    phone: '8 (495) 629 95 01',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.743411,
                        long: 37.542626,
                    },
                    title: 'Кутузовский проспект',
                    address: 'Кутузовский проспект, 26к1',
                    phone: '8 (495) 139 41 33',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.823216,
                        long: 37.497468,
                    },
                    title: 'Метрополис',
                    address: 'Ленинградское шоссе, 16а, стр.4',
                    phone: '8 (495) 139 33 36',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 23:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.731713,
                        long: 37.487353,
                    },
                    title: 'Времена года',
                    address: 'Кутузовский проспект, 48, ТРЦ "Времена года"',
                    phone: '8 (495) 258 35 08',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.763310,
                        long: 37.594072,
                    },
                    title: 'Малая Бронная',
                    address: 'Малая Бронная улица, 27/14',
                    phone: '8 (495) 646 74 29',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Понедельник - четверг 10:00 - 22:00<br />' +
                        'Пятница - суббота 10:00 - 23:00<br />' +
                        'Воскресенье 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.800720,
                        long: 37.280590,
                    },
                    title: 'Архангельское Аутлет',
                    address: 'МО, городской округ Красногорск, д. Воронки, Новорижское шоссе, 5й&nbsp;км от МКАД',
                    phone: '8 (499) 678 05 64',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
                {
                    location: {
                        lat: 55.744769,
                        long: 37.566116,
                    },
                    title: 'Европейский',
                    address: 'пл. Киевского Вокзала, 2, ТРЦ Европейский, атриум «Москва», 2 этаж',
                    phone: '8 (495) 646 74 30',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Понедельник - четверг 10:00 - 22:00<br />' +
                        'Пятница - суббота 10:00 - 23:00<br />' +
                        'Воскресенье 10:00 - 22:00',
                    holidaysSchedule: '',
                },
            ],
        },
        {
            city: 'Санкт-Петербург',
            selected: 0,
            location: {
                lat: 59.938951,
                long: 30.315635,
            },
            stores: [
                {
                    location: {
                        lat: 59.927662,
                        long: 30.373441,
                    },
                    title: 'Невский проспект',
                    address: 'Невский проспект, 117',
                    phone: '8 (812) 418 25 45',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
            ],
        },
        {
            city: 'Ростов-на-Дону',
            selected: 0,
            location: {
                lat: 47.222078,
                long: 39.720349,
            },
            stores: [
                {
                    location: {
                        lat: 47.259770,
                        long: 39.720376,
                    },
                    title: 'Горизонт',
                    address: 'проспект Михаила Нагибина, 32/2, ТРЦ "Горизонт"',
                    phone: '8 (863) 333 29 93',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
            ],
        },
        {
            city: 'Сочи',
            selected: 0,
            location: {
                lat: 43.585525,
                long: 39.723062,
            },
            stores: [
                {
                    location: {
                        lat: 43.584945,
                        long: 39.716661,
                    },
                    title: 'ТЦ Гранд Марина',
                    address: 'Улица Несебрская 1а, ТЦ Гранд Марина',
                    phone: '8 (862) 555 28 39',
                    selfPickup: 0,
                    fitting: 0,
                    schedule: 'Ежедневно 10:00 - 22:00',
                    holidaysSchedule: '',
                },
            ],
        },
    ],
};

export default boutiques;
